<template>
  <div>
    <div class="container container-global">
      <div class="wrap-form">
        <div class="form-signin main-overlay">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { urlLogin } from "@/utils/url";


export default {
  name: "Callback",
  components: {
  },
  mounted(){ 
    if (this.$route.query.code) {
        const code = this.$route.query.code;
        this.$store.dispatch("auth/login_oidc", { code }).then(
          () => {
            this.$router.push("/");
          },
          (error) => {
            console.log(error)
            alert('Please try again later.');
            this.$router.push(urlLogin());
          }
        );
      }
  },
  methods: {
    
  },
};
</script>

<style scoped> 
</style>